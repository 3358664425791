table {
    display: block;
    width: 75%;
  }
  

  
  thead,
  tbody {
    display: block;
  }
  
  .specialtbody {
    display: block;
    max-height: 400px;
    overflow-x: auto;
    overflow-y: auto;
  }
  
  
  tr {
    display: table;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    text-align: center;
  }
  
  th,
  td,
  th div {
    box-shadow: 0 0 0 1px;
  }
  
  th div {
    position: absolute;
    top: 0;
    left: auto;
    width: 25%;
    background: gray;
  }

